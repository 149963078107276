import useNav from 'Hooks/useNav';
import useBreakpoints from 'Hooks/useBreakpoints';
import { isMobile } from 'Services/Util';

import { Button } from 'disco-ui';

import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function LeftButton() {
  const { backAction, toggleSidebar, navigate } = useNav();
  const bp = useBreakpoints();

  return (
    <div>
      {backAction ? (
        <Button
          display="inline-block"
          color="transparent"
          size="large"
          icon={<ArrowBackIcon />}
          onClick={navigate}
        >
          {bp.greaterThan.md ? backAction.label : ''}
        </Button>
      ) : isMobile() ? (
        <Button
          color="transparent"
          icon={<MenuIcon />}
          size="large"
          onClick={() => toggleSidebar()}
        />
      ) : null}
    </div>
  );
}

export default LeftButton;
