import React, { useEffect } from 'react';
import cn from 'classnames';

// optional module react-modal
let ReactModal;
try {
  ReactModal = require('react-modal');
} catch (err) {
  ReactModal = null;
}

export default function Modal({ className, children, ...modalProps }) {
  useEffect(() => {
    if (ReactModal) {
      ReactModal.setAppElement('body');
    }
  }, []);

  if (!ReactModal) {
    console.log(`[disco-ui] Modal requires optional module "react-modal"`);
    return <div />;
  }

  return (
    <ReactModal
      className={cn('sku-modal', className)}
      bodyOpenClassName="sku-modal--open overflow-hidden"
      overlayClassName="sku-modal--shade"
      {...modalProps}
    >
      {children}
    </ReactModal>
  );
}
