/* eslint-disable react-hooks/exhaustive-deps */
// abstract route to capture authtoken and refreshToken from backend
import { useEffect } from 'react';
import { parseQueryString } from 'Services/Util';
import useAppReducer from 'Hooks/useAppReducer';

export default function VerifyEmail(props) {
  const [, dispatch] = useAppReducer();

  useEffect(() => {
    const p = parseQueryString(props.location.search);
    const {
      access_token: accessToken = '',
      refresh_token: refreshToken = ''
    } = p;

    if (accessToken) {
      dispatch({
        type: 'TOKEN:SET',
        accessToken,
        refreshToken
      });

      // apply skipResult to bypass the result screen
      props.history.replace('/account_creation', { skipResult: true });
    } else {
      props.history.replace('/');
    }
  }, []);

  return <div />;
}
