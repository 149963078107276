// Ref: https://objectpartners.com/2018/12/05/migrate-from-react-loadable-to-react-suspense/
import { Component, lazy, Suspense } from 'react';

import Loader from 'sku-ui/Loader';
import Button from 'sku-ui/Button';

import ErrorImage from 'Assets/error_image.png';

function ErrorLog() {
  return (
    <div className="flex items-center justify-center">
      <div
        className="flex flex-col items-center justify-center text-center p-3"
        style={{ maxWidth: '600px' }}
      >
        <img src={ErrorImage} width={600} height={230} alt="Error" />
        <h1 className="font-bold text-3xl">Whoops!</h1>
        <p className="text-base mt-4 mb-6">
          It looks like something went wrong. Please try reloading the page. We
          are working hard to get your dashboard back up and running.
        </p>
        <Button
          className="w-24 font-semibold"
          size="sm"
          color="primary"
          onClick={() => window.location.reload(true)}
        >
          Reload
        </Button>
      </div>
    </div>
  );
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      const { errorComponent: ErrorComponent } = this.props;
      return <ErrorComponent {...this.state} />;
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default function Loadable(
  loader,
  { fallback = null, errorComponent = ErrorLog } = {}
) {
  const AsyncComponent = lazy(loader);
  return props => (
    <ErrorBoundary errorComponent={errorComponent}>
      <Suspense
        fallback={
          fallback || <Loader className="flex items-center justify-center" />
        }
      >
        <AsyncComponent {...props} />
      </Suspense>
    </ErrorBoundary>
  );
}
