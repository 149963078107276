import { Fragment } from 'react';

import Footer from 'Footer';

const withFooter = WrappedComponent => props => (
  <Fragment>
    <WrappedComponent {...props} />
    <div style={{ flex: 1 }} />
    <Footer />
  </Fragment>
);

export default withFooter;
