import { terms, privacy } from 'Config/ExternalLinks';

import './style.scss';

export default function Footer({ hideHorizontalRule }) {
  const year = new Date().getFullYear();
  return (
    <div className="Footer mb-3">
      {hideHorizontalRule ? (
        <div className="mt-4 mb-3" />
      ) : (
        <hr className="mt-4 mb-3" />
      )}
      <div className="flex items-center justify-between">
        <div>
          <a
            href={terms}
            target="_blank"
            rel="noopener noreferrer"
            className="Footer--link"
          >
            Terms
          </a>
          <a
            href={privacy}
            target="_blank"
            rel="noopener noreferrer"
            className="Footer--link ml-3"
          >
            Privacy
          </a>
        </div>
        <div>&copy; {year} Skupos</div>
      </div>
    </div>
  );
}
