import { createReducer } from 'Reducers/helpers';

const initState = {
  offerModal: {
    seen: false,
    lastSeen: null
  },
  dateModal: {
    seen: false,
    lastSeen: null
  },
  dismissSignageCard: false
};

// handerlers
function set(state, { name, data }) {
  return {
    ...state,
    [name]: data
  };
}

function reset(state) {
  return {
    ...initState
  };
}

const reducer = createReducer(
  {
    'ENGAGE:SET': set,
    'ENGAGE:RESET': reset
  },
  initState
);

export default reducer;
