import cn from 'classnames';
import capitalize from 'lodash/capitalize';
import numeral from 'numeral';

import useBreakpoints from 'Hooks/useBreakpoints';
import EngageBalanceWithdraw from 'Mutations/EngageBalanceWithdraw';

import { Card, Button, Tooltip, Badge, Loader } from 'disco-ui';

// react-router
import { useHistory } from 'react-router-dom';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HelpIcon from '@mui/icons-material/Help';

function EngageReimbursementCard({
  engageBalances,
  connectedAcctStatus,
  stripeLinkedAccount,
  setToast,
  availableToWithdrawAmount,
  pendingBalanceAmount,
  totalAmount,
  hasAcceptedPayoutTermsAndConditions,
  closeCard
}) {
  const history = useHistory();
  const bp = useBreakpoints();
  const lessThanSmall = bp.lessThan.sm;

  const { lastWithdrawAmount, mostRecentStatus } = engageBalances || {};

  const { status: linkedAccountStatus, bankAccountLastFour, bankName = '' } =
    stripeLinkedAccount || {};

  const {
    engageBalanceWithdraw,
    engageBalanceWithdrawInFlight
  } = EngageBalanceWithdraw();

  const displayBankname = () => {
    let name = bankName.split(' ').map(word => capitalize(word));
    return name.join(' ');
  };

  const onTransfer = () => {
    engageBalanceWithdraw({
      variables: { input: {} },
      onCompleted() {
        setToast({
          type: 'success',
          content: 'Success!'
        });
      },
      onError(err) {
        console.log('error:', err);
        !!err[0].path && err[0].path.includes('engageBalanceWithdraw')
          ? setToast({
              type: 'error',
              content: 'No funds available for transfer.'
            })
          : setToast({
              type: 'error',
              content:
                'Error in transferring funds. Please try again or call our help desk at 1 (800) 559-1358.'
            });
      }
    });
  };

  const lastWithdrawTooltip = `Amount of funds last withdrawn from this account.`;

  const availableFundsTooltip = `Total amount of funds that you 
  can withdraw to your bank account today. 
  Processing will take 2 - 3 business days 
  to arrive in your bank account.`;

  const pendingTooltip = `Pending funds refers to the money that you have earned 
  but cannot withdraw at this time. The availability of 
  these funds is determined by your enrolled programs
  and may carry over from month to month.`;

  const balanceTooltip = `Total amount of funds you will be reimbursed 
  as of today. This includes the amount that you 
  are able to withdraw today plus funds that 
  are pending (yet to be available for withdrawal).`;

  const withdrawPending = `After clicking Transfer Funds, reimbursements can take 
  2 - 3 days to show up in your bank account.`;
  const withdrawFailed = `We were unable to process your transfer, please 
  contact customer support at 1-800-559-1358.`;

  const hasWithdrawAmount = numeral(availableToWithdrawAmount).value() > 0;
  const setupReimbursementUrl = hasAcceptedPayoutTermsAndConditions
    ? '/reimbursement/edit'
    : '/reimbursement';

  const reimbursementOrCloseModal = url => {
    const goToUrl = url ? url : '/reimbursement';
    closeCard();
    history.push(goToUrl);
  };

  const withdrawBadge = {
    PENDING: (
      <div className="flex items-center pb-2">
        <Badge className="mr-1" color="warning">
          PROCESSING
        </Badge>
        <Tooltip underlined={false} content={withdrawPending}>
          <HelpIcon sx={{ fontSize: 15 }} />
        </Tooltip>
      </div>
    ),
    FAILED: (
      <div className="flex items-center pb-2">
        <Badge className="mr-1" color="danger">
          FAILED
        </Badge>
        <Tooltip underlined={false} content={withdrawFailed}>
          <HelpIcon sx={{ fontSize: 15 }} />
        </Tooltip>
      </div>
    )
  };

  const buttonAction = {
    CREATED: (
      <div onClick={() => reimbursementOrCloseModal(setupReimbursementUrl)}>
        <Button color="primary" size="large">
          Setup Reimbursement
        </Button>
      </div>
    ),
    PENDING: (
      <Button color="primary" size="large">
        <div onClick={() => reimbursementOrCloseModal()}>Verify Account</div>
      </Button>
    ),
    READY: (
      <Button
        className="block w-1/2"
        color="primary"
        size="large"
        disabled={
          mostRecentStatus === 'PENDING' ||
          engageBalanceWithdrawInFlight ||
          !hasWithdrawAmount
        }
        onClick={onTransfer}
      >
        {engageBalanceWithdrawInFlight ? (
          <Loader color="white" />
        ) : (
          'Transfer Funds'
        )}
      </Button>
    )
  };

  const contentSize = lessThanSmall ? { width: '280px' } : { width: '380px' };

  const defaultButton = (
    <Button color="primary" size="large">
      <div onClick={() => reimbursementOrCloseModal()}>Setup Reimbursement</div>
    </Button>
  );

  return (
    <div className="EngageReimbursementDropdown--content" style={contentSize}>
      <Card elevation="none">
        <div className="flex flex-col m-8">
          <div
            className={cn(
              'flex justify-between',
              lessThanSmall ? 'flex-col items-start' : 'items-center'
            )}
          >
            <p className="text-sm">Account:</p>

            {!stripeLinkedAccount ? (
              <p className="flex justify-end text-sm">No reimbursement setup</p>
            ) : (
              <div className="flex justify-end items-center">
                <AccountBalanceIcon sx={{ fontSize: 16 }} className="mr-2" />
                <p className="text-sm mr-1">{displayBankname()} </p>
                <p className="text-sm">Account {bankAccountLastFour}</p>
              </div>
            )}
          </div>
          <div
            className={cn(
              'flex justify-between mt-2',
              lessThanSmall ? 'flex-col items-start' : 'items-center'
            )}
          >
            <div className="flex items-center">
              <p className="text-sm">Last Withdrawal:</p>
              <Tooltip
                className="ml-1"
                underlined={false}
                content={lastWithdrawTooltip}
              >
                <HelpIcon sx={{ fontSize: 15 }} />
              </Tooltip>
            </div>
            <p className="flex justify-end text-sm">
              $
              {!engageBalances || !lastWithdrawAmount
                ? '0.00'
                : lastWithdrawAmount}
            </p>
          </div>
        </div>
        <hr />
        <div className="flex flex-col items-center m-8">
          {linkedAccountStatus === 'VERIFIED' &&
            withdrawBadge[mostRecentStatus]}
          <h1 className="text-primary">${availableToWithdrawAmount}</h1>
          <div className="flex items-center my-4">
            <p className="text-xs mr-1">Available Funds:</p>
            <Tooltip underlined={false} content={availableFundsTooltip}>
              <HelpIcon sx={{ fontSize: 15 }} />
            </Tooltip>
          </div>
          {buttonAction[connectedAcctStatus] ?? defaultButton}
        </div>
        <hr />
        <div className="flex items-start justify-center mt-4 mx-4 pb-3">
          <div className="flex flex-col pr-3">
            <p className="mb-4 text-sm">Pending:</p>
            <p className="text-sm">Total Balance:</p>
          </div>
          <div className="flex flex-col">
            <div className="flex items-center mb-3 shrink">
              <p className="mr-1 text-sm">${pendingBalanceAmount}</p>
              <Tooltip underlined={false} content={pendingTooltip}>
                <HelpIcon sx={{ fontSize: 15, color: '#999' }} />
              </Tooltip>
            </div>
            <div className="flex items-center shrink">
              <p className="mr-1 text-sm">
                ${!engageBalances || !totalAmount ? '0.00' : totalAmount}
              </p>
              <Tooltip underlined={false} content={balanceTooltip}>
                <HelpIcon sx={{ fontSize: 15, color: '#999' }} />
              </Tooltip>
            </div>
          </div>
        </div>
        <div
          className="flex flex-col justify-content items-center mb-4"
          style={{ color: '#999' }}
        >
          <p className="text-mini">
            *The amount does not include Altria and RJR reimbursement.
          </p>
        </div>
      </Card>
    </div>
  );
}

export default EngageReimbursementCard;
