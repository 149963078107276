/**
 * @generated SignedSource<<28ae4c0d5b8c0dddb62f1960c3cc0aae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dbId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "StripeConnectedAccount",
  "kind": "LinkedField",
  "name": "connectedAccounts",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stripeAccountId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StripeLinkedAccount",
      "kind": "LinkedField",
      "name": "stripeLinkedAccount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bankAccountLastFour",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bankAccountRoutingNumber",
          "storageKey": null
        },
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "stripeConnectedAccountId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EngageBalanceWithdrawMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EngageBalanceWithdrawPayload",
        "kind": "LinkedField",
        "name": "engageBalanceWithdraw",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreChain",
                "kind": "LinkedField",
                "name": "storeChain",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EngageBalanceWithdrawMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EngageBalanceWithdrawPayload",
        "kind": "LinkedField",
        "name": "engageBalanceWithdraw",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreChain",
                "kind": "LinkedField",
                "name": "storeChain",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b7a63736091886551517f27600489401",
    "id": null,
    "metadata": {},
    "name": "EngageBalanceWithdrawMutation",
    "operationKind": "mutation",
    "text": "mutation EngageBalanceWithdrawMutation(\n  $input: EngageBalanceWithdrawInput!\n) {\n  engageBalanceWithdraw(input: $input) {\n    clientMutationId\n    viewer {\n      storeChain {\n        connectedAccounts {\n          dbId\n          status\n          stripeAccountId\n          stripeLinkedAccount {\n            bankAccountLastFour\n            bankAccountRoutingNumber\n            dbId\n            status\n            stripeConnectedAccountId\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "132810221beee586fc47c8d4486524bb";

module.exports = node;
