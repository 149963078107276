/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, Suspense, useMemo, useRef } from 'react';
import cn from 'classnames';
import { DateTime } from 'luxon';
import find from 'lodash/find';
import useAppReducer from 'Hooks/useAppReducer';
import useNav from 'Hooks/useNav';
import { isMobile } from 'Services/Util';

// react-router
import { Link, NavLink, useLocation } from 'react-router-dom';

// Relay
import graphql from 'babel-plugin-relay/macro';
import { useQueryLoader, usePreloadedQuery } from 'react-relay';

// Components
import NavDropdown from 'Navigation/NavDropdown';
import EngageReimbursementDropdown from 'Navigation/EngageReimbursementDropdown';
import PromosSubNav from 'Navigation/PromosSubNav';
import DownloadSyncModal from './DownloadSyncModal';
import AppSwitcherDropdown from 'Navigation/AppSwitcherDropdown';
import MenuIcon from '@mui/icons-material/Menu';

// Images
import logo from 'Assets/logo-s-white.png';

import './style.scss';

function Navigation({ queryRef }) {
  const [appState, dispatch] = useAppReducer();
  const { toggleSidebar } = useNav();
  const location = useLocation();
  const [openSynModal, setOpenSyncModal] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const { viewer } = usePreloadedQuery(
    graphql`
      query NavigationQuery {
        viewer {
          me {
            dbId
            firstName
            lastName
            email
            phoneNumber
            createdAt
            user_hash: intercomHMAC
          }
          storeChain {
            dbId
            hasAcceptedPayoutTermsAndConditions
            connectedAccounts {
              dbId
            }
            engageStoreChainStatuses
            stores {
              totalCount
              edges {
                node {
                  cloudSync {
                    disabled
                  }
                }
              }
            }
            hasPurchasedEngage
          }
        }
      }
    `,
    queryRef
  );

  const { firstName } = viewer.me || {};

  const title = !!firstName && firstName.charAt(0);

  const { connectedAccounts, stores } = viewer.storeChain || {};

  const hasConnectedAccount = appState.feature.payout_connectedAccts;

  const onPromosPg =
    location.pathname.includes('/engage') &&
    location.pathname !== '/engage_checkout' &&
    location.pathname !== '/engage_signup';

  const hasSyncStore = useMemo(() => {
    return !!find(stores.edges, ({ node }) => {
      return node?.cloudSync?.disabled === false;
    });
  }, [stores]);

  useEffect(() => {
    // GTM
    if (window.dataLayer) {
      const { hasOptedInStores, hasActiveEnrollmentStores } = JSON.parse(
        viewer.storeChain.engageStoreChainStatuses
      );

      window.dataLayer.push({
        event: 'loggedin',
        user: viewer.me || {},
        storeChain: viewer.storeChain || {},
        userCreatedAt: DateTime.fromSeconds(viewer.me.createdAt).toFormat(
          'MMM dd, yyyy'
        ),
        engageStoreChainStatuses_hasOptedInStores: hasOptedInStores,
        engageStoreChainStatuses_hasActiveEnrollmentStores: hasActiveEnrollmentStores
      });
    }
  }, []);

  useEffect(() => {
    if (
      !hasConnectedAccount &&
      !!connectedAccounts &&
      connectedAccounts.length > 0
    ) {
      dispatch({
        type: 'FEATURE:SET',
        name: 'payout_connectedAccts',
        enable: true
      });
    }
  }, [connectedAccounts]);

  const handleDropdownClick = dropdownName => {
    setOpenDropdown(prev => (prev === dropdownName ? null : dropdownName));
  };

  const ref = useRef(null);
  useEffect(() => {
    if (openDropdown) {
      const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenDropdown(null);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [openDropdown]);

  const mobile = isMobile();

  return (
    <div className={cn(onPromosPg && !mobile ? 'h-32' : 'h-24')}>
      <div className="Navigation fixed w-full z-50">
        <header
          className={cn(
            'Navigation--container flex justify-between bg-indigo-500',
            !mobile && 'px-7'
          )}
        >
          {mobile ? (
            <div className="flex items-center justify-between bg-indigo-500">
              <MenuIcon
                className="ml-4"
                onClick={() => toggleSidebar()}
                style={{ color: '#FFF' }}
              />
              <Link to="/home" replace>
                <img className="h-5 w-auto pl-5" src={logo} alt="Skupos Logo" />
              </Link>
            </div>
          ) : (
            <div className="flex items-center">
              <Link to="/home" replace>
                <img className="h-7 w-auto" src={logo} alt="Skupos Logo" />
              </Link>
              <NavLink
                to="/home"
                className="Navigation--link pr-6 pl-10"
                activeClassName="active"
              >
                Home
              </NavLink>
              <NavLink
                to="/chain"
                className="Navigation--link px-6"
                activeClassName="active"
              >
                Stores
              </NavLink>
              <NavLink
                to="/engage"
                className="Navigation--link px-6"
                activeClassName="active"
              >
                Programs
              </NavLink>
              <NavLink
                to="/scandata"
                className="Navigation--link px-6"
                activeClassName="active"
              >
                Scan Data
              </NavLink>
              <NavLink
                to={'/reimbursement'}
                className="Navigation--link px-6"
                activeClassName="active"
              >
                Payout
              </NavLink>
            </div>
          )}
          <div
            className="MultiDropdowns flex items-center font-medium"
            ref={ref}
          >
            <EngageReimbursementDropdown
              isOpen={openDropdown === 'EngageReimbursementDropdown'}
              onClick={() => handleDropdownClick('EngageReimbursementDropdown')}
            />
            <NavDropdown
              title={title}
              showTour={stores?.totalCount > 0}
              hasSyncStore={hasSyncStore}
              setOpenSyncModal={setOpenSyncModal}
              isOpen={openDropdown === 'NavDropdown'}
              onClick={() => handleDropdownClick('NavDropdown')}
            />
            <AppSwitcherDropdown
              isOpen={openDropdown === 'AppSwitcherDropdown'}
              onClick={() => handleDropdownClick('AppSwitcherDropdown')}
            />
          </div>
        </header>
        {onPromosPg && !mobile && <PromosSubNav />}
      </div>
      <DownloadSyncModal
        openSynModal={openSynModal}
        setOpenSyncModal={setOpenSyncModal}
      />
    </div>
  );
}

export default function NavigationLoader() {
  const [queryRef, loadQuery] = useQueryLoader(
    require('./__generated__/NavigationQuery.graphql')
  );

  useEffect(() => {
    loadQuery();
  }, [loadQuery]);

  return (
    <Suspense>
      {queryRef != null ? <Navigation queryRef={queryRef} /> : null}
    </Suspense>
  );
}
