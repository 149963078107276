/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, Suspense } from 'react';

//react-router
import { Link } from 'react-router-dom';

// Relay
import graphql from 'babel-plugin-relay/macro';
import { useQueryLoader, usePreloadedQuery } from 'react-relay';

// Components
import CloverLeftButton from 'Navigation/CloverLeftButton';
import CloverDropdown from 'Navigation/CloverDropdown';
import CloverSidebar from 'Navigation/CloverSidebar';

// Images
import logo from 'Assets/logo-s-white.png';

// Styles
import './style.scss';

function CloverNav({ queryRef }) {
  const { viewer } = usePreloadedQuery(
    graphql`
      query CloverNavQuery {
        viewer {
          me {
            username
            firstName
            lastName
            email
            phoneNumber
            createdAt
            dbId
            role
            user_hash: intercomHMAC
          }
          storeChain {
            dbId
          }
        }
      }
    `,
    queryRef
  );

  useEffect(() => {
    // GTM
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'loggedin',
        user: viewer.me || {},
        storeChain: viewer.storeChain || {}
      });
    }
  }, []);

  return (
    <div className="CloverNav flex justify-between items-center">
      <CloverLeftButton sidebar={<CloverSidebar />} />
      <Link className="CloverNav--logo" to="sync" replace>
        <img src={logo} style={{ width: '100%' }} alt="Skupos Logo" />
      </Link>
      <CloverDropdown />
    </div>
  );
}

export default function CloverNavLoader() {
  const [queryRef, loadQuery] = useQueryLoader(
    require('./__generated__/CloverNavQuery.graphql')
  );

  useEffect(() => {
    loadQuery();
  }, [loadQuery]);

  return (
    <Suspense>
      {queryRef != null ? <CloverNav queryRef={queryRef} /> : null}
    </Suspense>
  );
}
