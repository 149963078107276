import { NavLink } from 'react-router-dom';

// Components
import { Button } from 'disco-ui';

// Icons
import StoreIcon from '@mui/icons-material/Store';
import CloseIcon from '@mui/icons-material/Close';
import DnsIcon from '@mui/icons-material/Dns';

import './style.scss';

function CloverSidebar({ onClick }) {
  return (
    <div className="CloverSidebar">
      <div className="CloverSidebar--overlay" onClick={onClick} />
      <div className="CloverSidebar--menu shadow-5">
        <div className="CloverSidebar--header flex items-center justify-between pl-4 pr-2">
          <h3 className="">Menu</h3>
          <Button
            color="transparent"
            size="large"
            icon={<CloseIcon sx={{ color: '#999' }} />}
            onClick={onClick}
          />
        </div>
        <div className="py-3">
          <NavLink
            to="/clover"
            className="CloverSidebar--link flex items-center py-3 px-4"
            onClick={onClick}
            activeClassName="active"
          >
            <StoreIcon className="mr-3" />
            Store Selection
          </NavLink>

          <NavLink
            to="/discount"
            className="CloverSidebar--link flex items-center py-3 px-4"
            onClick={onClick}
            activeClassName="active"
          >
            <DnsIcon className="mr-3" />
            Discount Settings
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default CloverSidebar;
