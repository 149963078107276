import { createContext, useContext, useReducer } from 'react';
import { combineReducers, persistReducer } from 'Reducers/helpers';

import TokenReducer from 'Reducers/Token';
import CloverReducer from 'Reducers/Clover';
import FeatureReducer from 'Reducers/Feature';
import EngageReducer from 'Reducers/Engage';
import ChainReducer from 'Reducers/Chain';

const AppReducer = combineReducers({
  token: persistReducer({ key: 'token', storage: localStorage })(TokenReducer),
  clover: persistReducer({ key: 'clover', storage: sessionStorage })(
    CloverReducer
  ),
  feature: persistReducer({ key: 'feature', storage: sessionStorage })(
    FeatureReducer
  ),
  engage: persistReducer({ key: 'engage', storage: sessionStorage })(
    EngageReducer
  ),
  chain: persistReducer({ key: 'chain', storage: localStorage })(ChainReducer)
});

const initState = AppReducer();

export const AppReducerContext = createContext();

// for provider
export function useAppReducer() {
  return useReducer(AppReducer, initState);
}

// for consumer
export default function useAppReducerContext() {
  return useContext(AppReducerContext);
}
