import React from 'react';
import downtimeImg from 'Assets/downtime_image_light.png';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="flex flex-col items-center mt-10 mx-2 text-center">
          <div className="pb-3 lg:w-2/5">
            <img src={downtimeImg} alt="BE Downtime" />
          </div>
          <h1 className="font-bold pb-3 mx-3">
            We are updating the Skupos Experience
          </h1>
          <p>We will be right back, sorry for any inconvenience</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
