import useLogin from 'Hooks/useLogin';

// Components
import { Alert, Button, Card, TextInput } from 'disco-ui';

import Footer from 'Footer';
import InputErrorToolTip from 'Components/InputErrorToolTip';

// Icons
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LockIcon from '@mui/icons-material/Lock';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

// Styles
import './style.scss';

// Images
import logo from 'Assets/logo-green-on-white.png';

function CloverLogin(props) {
  const {
    payload,
    pending,
    error,
    inputChange,
    submit,
    openExternal
  } = useLogin(props);

  return (
    <div className="CloverLogin flex items-center justify-center">
      <div className="CloverLogin--container">
        <Card display="block" padding={0}>
          <div className="CloverLogin--form">
            <div className="flex justify-center mb-4">
              <img className="CloverLogin--logo" src={logo} alt="Skupos Logo" />
            </div>
            <h3 className="text-center mb-4">Log In to Skupos</h3>
            <form onSubmit={submit}>
              {!pending && !!error.request && (
                <Alert color="danger" icon={<ErrorOutlineIcon />}>
                  Invalid username/password.
                </Alert>
              )}
              <div className="mt-3">
                <TextInput
                  type="text"
                  placeholder="Username"
                  display="block"
                  prepend={<AccountBoxIcon />}
                  onChange={inputChange}
                  name="username"
                  value={payload.username}
                  color={error.username ? 'danger' : ''}
                  append={<InputErrorToolTip content={error.username} />}
                />
              </div>
              <div className="mt-2">
                <TextInput
                  type="password"
                  placeholder="Password"
                  display="block"
                  prepend={<LockIcon />}
                  onChange={inputChange}
                  name="password"
                  value={payload.password}
                  color={error.password ? 'danger' : ''}
                  append={<InputErrorToolTip content={error.password} />}
                />
              </div>
              <div className="flex justify-center mt-4">
                <Button
                  type="submit"
                  display="w-full"
                  color="primary"
                  activated={pending}
                  disabled={pending}
                >
                  {pending ? 'Logging In' : 'Log In'}
                </Button>
              </div>
            </form>
            <div className="flex justify-center mt-4">
              <div
                className="text-sm text-link"
                onClick={openExternal('signup')}
              >
                New User? Learn more
              </div>
            </div>
          </div>
        </Card>
        <Footer hideHorizontalRule />
      </div>
    </div>
  );
}

export default CloverLogin;
