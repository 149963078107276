import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import { createReducer } from 'Reducers/helpers';

const initState = {
  cloverMode: false,
  merchantId: null,
  authorizationCode: null
};

// handerlers
function set(state, { cloverMode, merchantId, authorizationCode }) {
  const clover = omitBy(
    {
      cloverMode,
      merchantId,
      authorizationCode
    },
    isUndefined
  );

  return {
    ...state,
    ...clover
  };
}

function reset(state) {
  return {
    ...initState
  };
}

const reducer = createReducer(
  {
    'CLOVER:SET': set,
    'CLOVER:RESET': reset
  },
  initState
);

export default reducer;
