/* eslint-disable react-hooks/exhaustive-deps */
// abstract route to capture authtoken and refreshToken from backend
import { useEffect } from 'react';
import { parseQueryString } from 'Services/Util';
import api from 'Services/WebApi';
import useAppReducer from 'Hooks/useAppReducer';

function AutoLogin(props) {
  const [, dispatch] = useAppReducer();

  useEffect(() => {
    const p = parseQueryString(props.location.search);
    const {
      access_token: accessToken = '',
      refresh_token: refreshToken = '',
      username = '',
      password = ''
    } = p;

    if (accessToken && refreshToken) {
      dispatch({
        type: 'TOKEN:SET',
        accessToken,
        refreshToken,
        loggedIn: true
      });
      props.history.replace('/');
    } else if (username && password) {
      api.login({ username, password }).then(response => {
        if (response.ok) {
          const {
            access_token: accessToken,
            refresh_token: refreshToken,
            registered
          } = response.data;
          if (registered) {
            dispatch({
              type: 'TOKEN:SET',
              accessToken,
              refreshToken,
              loggedIn: true
            });
          } else {
            dispatch({
              type: 'TOKEN:SET',
              accessToken,
              refreshToken
            });
            props.history.replace('/account_creation');
          }
        } else {
          props.history.replace('/');
        }
      });
    } else {
      props.history.replace('/');
    }
  }, []);

  return <div />;
}

export default AutoLogin;
