import { useEffect, useState } from 'react';
import cn from 'classnames';

export default function Dropdown({
  position = 'bottom left',
  className,
  width = 'auto',
  menuHorizontalOffset = 0,
  menuVerticalOffset = 40,
  children,
  disabled,
  button,
  isOpenCustom,
  onToggleCustom,
  customDropdown = false
}) {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (document.getElementById('intercom-positioner-tree')) {
        setOpen(false);
      }
    });
    observer.observe(document.body, { childList: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  function toggleOpen() {
    if (disabled) return;
    setOpen(!isOpen);
  }

  const menuStyle = {};

  // width
  if (typeof width === 'number') {
    menuStyle.width = `${width}px`;
  } else if (typeof width === 'string') {
    menuStyle.width = width;
  }

  // position
  if (position.indexOf('top') > -1) {
    menuStyle.bottom = `${menuVerticalOffset}px`;
  } else if (position.indexOf('bottom') > -1) {
    menuStyle.top = `${menuVerticalOffset}px`;
  }
  if (position.indexOf('left') > -1) {
    menuStyle.left = `${menuHorizontalOffset}px`;
  } else if (position.indexOf('right') > -1) {
    menuStyle.right = `${menuHorizontalOffset}px`;
  }

  const isOpenDropdown = customDropdown ? isOpenCustom : isOpen;
  const onClickDropdown = customDropdown ? onToggleCustom : toggleOpen;

  return (
    <div
      className={cn('sku-dropdown', { isOpen }, className)}
      onClick={onClickDropdown}
    >
      {typeof button === 'function' ? button({ isOpen, setOpen }) : button}
      {children && isOpenDropdown ? (
        <div
          className="sku-dropdown--menu"
          style={menuStyle}
          children={children}
        />
      ) : null}
    </div>
  );
}
