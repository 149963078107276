import { Switch, useLocation } from 'react-router-dom';

import { useTransition, animated } from 'react-spring';

export default function AnimatedSwitch({ children }) {
  const location = useLocation();

  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });
  return (
    <div className="switch-wrapper">
      {transitions.map(({ item, props, key }) => (
        <animated.div
          key={key}
          style={{
            ...props
          }}
        >
          <Switch location={item}>{children}</Switch>
        </animated.div>
      ))}
    </div>
  );
}
