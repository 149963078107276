import { useRef } from 'react';
import useNav from 'Hooks/useNav';
import { useClickAway } from 'react-use';

// react-router
import { NavLink, Link, useLocation } from 'react-router-dom';

// Icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import HomeIcon from '@mui/icons-material/Home';
import { ReactComponent as ScanData } from 'Assets/ScanData.svg';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import './style.scss';

function SideBarIcon({ icon }) {
  return (
    <div className="bg-indigo-200 text-white rounded-full h-6 w-6 flex items-center justify-center mr-3">
      {icon}
    </div>
  );
}
function Sidebar() {
  const { toggleSidebar, sidebarMode } = useNav();
  const location = useLocation();

  const ref = useRef(null);
  useClickAway(ref, () => {
    toggleSidebar();
  });

  return (
    <div className="Sidebar" ref={ref}>
      {sidebarMode === 'float' && (
        <div className="Sidebar--overlay" onClick={() => toggleSidebar()} />
      )}
      <div className="Sidebar--menu shadow-5">
        <div className="Sidebar--header"></div>
        <div className="py-9">
          <NavLink
            to="/home"
            className="Sidebar--link flex items-center w-3/4 p-3 ml-3"
            activeClassName="active"
            onClick={() => toggleSidebar()}
          >
            <SideBarIcon icon={<HomeIcon style={{ fontSize: 15 }} />} />
            Home
          </NavLink>
          <NavLink
            to="/chain"
            className="Sidebar--link flex items-center w-3/4 p-3 ml-3"
            activeClassName="active"
            onClick={() => toggleSidebar()}
          >
            <SideBarIcon icon={<DashboardIcon style={{ fontSize: 15 }} />} />
            Stores
          </NavLink>
          <Link
            to="/engage/manage"
            className="Sidebar--link flex items-center w-3/4 p-3 ml-3"
            onClick={() => toggleSidebar()}
          >
            <SideBarIcon icon={<LocalOfferIcon style={{ fontSize: 15 }} />} />
            Programs
          </Link>
          <div className="pl-9">
            <div className="border-l-2 border-indigo-200 space-y-2">
              <NavLink
                to="/engage/manage"
                className="Sidebar--link flex items-center w-3/4 p-3 ml-3"
                isActive={() =>
                  ['/engage', '/engage/manage'].includes(location.pathname)
                }
                onClick={() => toggleSidebar()}
              >
                Manage
              </NavLink>
              <NavLink
                to="/engage/performance"
                className="Sidebar--link flex items-center w-3/4 p-3 ml-3"
                activeClassName="active"
                onClick={() => toggleSidebar()}
              >
                Performance
              </NavLink>
            </div>
          </div>
          <NavLink
            to="/scandata"
            className="Sidebar--link flex items-center w-3/4 p-3 ml-3"
            activeClassName="active"
            onClick={() => toggleSidebar()}
          >
            <SideBarIcon icon={<ScanData className="w-4 h-4" />} /> Scan Data
          </NavLink>
          <NavLink
            to="/reimbursement"
            className="Sidebar--link flex items-center  w-3/4 p-3 ml-3"
            onClick={() => toggleSidebar()}
            activeClassName="active"
          >
            <SideBarIcon icon={<AttachMoneyIcon style={{ fontSize: 18 }} />} />
            Payout
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
