import { Tooltip } from 'disco-ui';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function InputErrorToolTip({ content, position = 'top' }) {
  if (!!content && !!content.join) {
    content = content.join('\n');
  }

  return (
    !!content && (
      <Tooltip content={content} position={position} underlined={false}>
        <ErrorOutlineIcon />
      </Tooltip>
    )
  );
}

export default InputErrorToolTip;
