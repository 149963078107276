import { useState } from 'react';

import Modal from 'sku-ui/Modal';
import Card from 'sku-ui/Card';
import Toggle from 'sku-ui/Toggle';
import Button from 'sku-ui/Button';
import CloseIcon from '@mui/icons-material/Close';

// Constants
import SYNC_DOWNLOAD_URL from 'Constants/SYNC_DOWNLOAD_URL';

export default function DownloadSyncModal({ openSynModal, setOpenSyncModal }) {
  const [checked, setChecked] = useState(false);

  const onModalClose = () => {
    setChecked(false);
    setOpenSyncModal(false);
  };

  return (
    <Modal isOpen={openSynModal} className="h-full w-1/3">
      <Card className="m-auto p-5" radius={5}>
        <div className="flex justify-between">
          <div className="text-lg font-bold">Download Desktop Sync</div>
          <CloseIcon
            className="cursor-pointer text-gray-300"
            fontSize="small"
            onClick={onModalClose}
          />
        </div>
        <div className="text-xs mt-6">
          At least one of your stores is running CloudSync. Installing Desktop
          Sync on these stores may lead to reporting issues.
        </div>
        <Toggle
          className="text-xs mt-6"
          type="checkbox"
          checked={checked}
          onChange={() => setChecked(!checked)}
        >
          I understand and want to continue with the download
        </Toggle>
        <div className="flex justify-end items-center mt-7">
          <div className="cursor-pointer text-xs underline mr-5 skupos-intercom-chat-launcher">
            Contact Support
          </div>
          <Button size="xs" disabled={!checked} onClick={onModalClose}>
            <a
              href={`${SYNC_DOWNLOAD_URL}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Continue Download
            </a>
          </Button>
        </div>
      </Card>
    </Modal>
  );
}
