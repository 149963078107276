/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import validate from 'validate.js';
import api from 'Services/WebApi';
import useAppReducer from 'Hooks/useAppReducer';

const LOGIN_CONSTRAINTS = {
  username: {
    presence: { allowEmpty: false }
  },
  password: {
    presence: { allowEmpty: false }
  }
};

export default function useLogin(props) {
  const [appState, dispatch] = useAppReducer();
  const [payload, setPayload] = useState({ username: '', password: '' });
  const [error, setError] = useState({});
  const [pending, setPending] = useState(false);

  const isClover = !!appState.clover.merchantId && appState.clover.cloverMode;
  const showClover = appState.feature.clover;

  useEffect(() => {
    if (pending) {
      api.login(payload).then(response => {
        if (response.ok) {
          const {
            access_token: accessToken,
            refresh_token: refreshToken,
            registered
          } = response.data;
          if (response.data.role === 'cstore_owner') {
            if (registered) {
              dispatch({
                type: 'TOKEN:SET',
                accessToken,
                refreshToken,
                loggedIn: true
              });
            } else {
              dispatch({
                type: 'TOKEN:SET',
                accessToken,
                refreshToken
              });
              props.history.replace('/account_creation');
            }
          } else {
            // 'cstore_manager'
            setError({
              request:
                'Uh Oh! It looks like you attempted to sign in with a store-level user. Please contact the account owner to acquire the login credentials for the main account.'
            });
            setPending(false);
          }
        } else {
          if (response.problem === 'NETWORK_ERROR') {
            setError({
              request:
                'We are updating the Skupos Experience and will be right back. Sorry for any inconvenience.'
            });
          } else {
            setError({
              request: 'Invalid username/password'
            });
          }
          setPending(false);
        }
      });
    }
  }, [pending]);

  function inputChange(e) {
    let { name, value } = e.target;

    setPayload({
      ...payload,
      [name]: value
    });

    setError({
      ...error,
      [name]: '',
      request: ''
    });
  }

  function submit(e) {
    e.preventDefault();
    let error = validate(payload, LOGIN_CONSTRAINTS);
    if (error) {
      setError(error);
    } else {
      setPending(true);
    }
  }

  const openExternal = url => e => {
    if (!window.require) {
      if (url === 'signup' && isClover && showClover) {
        props.history.push('clover_signup');
      } else {
        props.history.push(url);
      }
    } else {
      const shell = window.require('electron').shell;
      shell.openExternal(`${window.location.origin}/${url}`);
    }
  };

  return {
    payload,
    setPayload,
    error,
    setError,
    pending,
    inputChange,
    submit,
    openExternal
  };
}
