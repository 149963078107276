import reduce from 'lodash/reduce';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';

export function createReducer(handlers, initState) {
  return function(state = initState, action) {
    state = isEmpty(state) ? { ...initState } : state;
    if (typeof handlers[action.type] === 'function') {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
}

export function combineReducers(reducers) {
  return function(rootState = {}, action = {}) {
    return reduce(
      reducers,
      (rootState, reducer, name) => {
        const localState = rootState[name];
        const newLocalState = reducer(localState, action);
        if (localState !== newLocalState)
          return merge({}, rootState, { [name]: newLocalState });
        else return rootState;
      },
      rootState
    );
  };
}

// fix the weird format from legacy redux-persist
function fixData(obj) {
  return reduce(
    obj,
    (acc, v, k) => {
      if (v === 'null') {
        acc[k] = null;
      } else if (v === 'false' || v === 'true') {
        acc[k] = v === 'true';
      } else {
        acc[k] = v;
      }
      return acc;
    },
    {}
  );
}

export const persistReducer = ({ key, storage }) => reducer => (
  state,
  action
) => {
  if (!state) {
    const persistState = fixData(JSON.parse(storage.getItem(`persist:${key}`)));
    if (persistState) state = persistState;
  }

  const nextState = reducer(state, action);
  if (!!state && nextState !== state)
    storage.setItem(`persist:${key}`, JSON.stringify(nextState));

  return nextState;
};
