/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, Suspense } from 'react';

import { Link } from 'react-router-dom';

// Relay
import graphql from 'babel-plugin-relay/macro';
import { useQueryLoader, usePreloadedQuery } from 'react-relay';

// Context
import useNav from 'Hooks/useNav';

// Components
import SyncDropdown from 'Navigation/SyncDropdown';
import LeftButton from 'Navigation/LeftButton';

// Images
import logo from 'Assets/logo-s-white.png';

// Styles
import './style.scss';

function SyncNav({ queryRef }) {
  const { viewer } = usePreloadedQuery(
    graphql`
      query SyncNavQuery {
        viewer {
          me {
            username
            firstName
            lastName
            createdAt
            email
            phoneNumber
            dbId
            role
            user_hash: intercomHMAC
          }
          storeChain {
            dbId
          }
        }
      }
    `,
    queryRef
  );

  const { backAction } = useNav();
  const [syncInfo, setSyncInfo] = useState({});

  useEffect(() => {
    // GTM
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'loggedin',
        user: viewer.me || {},
        storeChain: viewer.storeChain || {}
      });
    }

    if (!window.require) return;
    // register event from electron ipcRenderer
    const { ipcRenderer } = window.require('electron');
    ipcRenderer.send('get-sync-info', 'getSyncInfo');

    ipcRenderer.on('get-sync-info-response', (event, syncInfo) => {
      setSyncInfo(syncInfo);
    });
  }, []);

  return (
    <div className="SyncNav flex justify-between items-center">
      <LeftButton backAction={backAction} />
      <Link className="SyncNav--logo" to="sync" replace>
        <img src={logo} style={{ width: '100%' }} alt="Skupos Logo" />
      </Link>
      <SyncDropdown syncInfo={syncInfo} />
    </div>
  );
}

export default function SyncNavLoader() {
  const [queryRef, loadQuery] = useQueryLoader(
    require('./__generated__/SyncNavQuery.graphql')
  );

  useEffect(() => {
    loadQuery();
  }, [loadQuery]);

  return (
    <Suspense>
      {queryRef != null ? <SyncNav queryRef={queryRef} /> : null}
    </Suspense>
  );
}
