/* eslint-disable import/first */
/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect } from 'react';
import cn from 'classnames';
import { isSync } from 'Services/Util';
import { cache as relayCache } from 'Relay/environment';

// Hooks
import useAppReducer from 'Hooks/useAppReducer';
import useBreakpoints from 'Hooks/useBreakpoints';

// Navigation
import { NavContext, useNav } from 'Hooks/useNav';
import Navigation from 'Navigation';
import SyncNav from 'Navigation/SyncNav';
import CloverNav from 'Navigation/CloverNav';
import Sidebar from 'Navigation/Sidebar';

// Router
import { Route, Redirect, useLocation } from 'react-router-dom';
import AnimatedSwitch from 'Components/AnimatedSwitch';

// Normal Routes
import Login from 'Routes/Login';
import AutoLogin from 'Routes/AutoLogin';
import VerifyEmail from 'Routes/VerifyEmail';
import CloverAuth from 'Routes/CloverAuth';
import CloverLogin from 'Routes/CloverLogin';

// LazyLoad Routes
import Loadable from 'Components/Loadable';
const NewSignUp = Loadable(() => import('./NewSignUp'));
const SignUp = Loadable(() => import('./SignUp'));
const NewAccountCreation = Loadable(() => import('./NewAccountCreation'));
const AccountCreation = Loadable(() => import('./AccountCreation'));
const ResetPassword = Loadable(() => import('./ResetPassword'));
const Profile = Loadable(() => import('./Profile'));
const Chain = Loadable(() => import('./Chain'));
const Sync = Loadable(() => import('./Sync'));
const SyncSetting = Loadable(() => import('./SyncSetting'));
const Clover = Loadable(() => import('./Clover'));
const ScanData = Loadable(() => import('./ScanData'));
const Engage = Loadable(() => import('./Engage'));
const EngageCheckout = Loadable(() => import('./EngageCheckout'));
const DiscountSettings = Loadable(() => import('./DiscountSettings'));
const Reimbursement = Loadable(() => import('./Reimbursement'));
const ReimbursementEdit = Loadable(() => import('./ReimbursementEdit'));
const Home = Loadable(() => import('./Home'));

// Footer
import withFooter from 'Footer/withFooter';
const ChainWithFooter = withFooter(Chain);
const ProfileWithFooter = withFooter(Profile);
const ScanDataWithFooter = withFooter(ScanData);
const EngageWithFooter = withFooter(Engage);
const EngageCheckoutWithFooter = withFooter(EngageCheckout);
const ReimbursementWithFooter = withFooter(Reimbursement);
const ReimbursementEditWithFooter = withFooter(ReimbursementEdit);
const HomeWithFooter = withFooter(Home);

// Error Component
import ErrorBoundary from 'Components/ErrorBoundry';

// Style
import './style.css';

const animatedSwitchProps = {
  atEnter: { opacity: 0 },
  atLeave: { opacity: 0 },
  atActive: { opacity: 1 },
  className: 'switch-wrapper'
};

function AppRoutes(props) {
  const [appState] = useAppReducer();
  const nav = useNav();
  const bp = useBreakpoints();
  const prevLoggedIn = useRef();

  const { loggedIn } = appState.token;
  const isClover = !!appState.clover.merchantId && appState.clover.cloverMode;
  const showClover = appState.feature.clover;
  const showCloverMp = appState.feature.clover_mp;

  const showNewEnrollFlow = appState.feature.newEnrollFlow;

  const contentId = isSync() ? 'sync-content' : 'content';

  if (loggedIn !== prevLoggedIn.current) {
    if (!loggedIn) relayCache.clear();
    prevLoggedIn.current = loggedIn;
  }

  const location = useLocation();
  const { accessToken, refreshToken } = appState.token;
  const inSession = !!accessToken && !!refreshToken;
  const prevRedirectLocation = useRef();

  const redirect = !!window.sessionStorage.getItem('redirectLocation');

  const sessionLocation = redirect
    ? JSON.parse(window.sessionStorage.getItem('redirectLocation'))
    : {};

  useEffect(() => {
    return () => {
      if (loggedIn) window.location.reload();
    };
  }, [loggedIn]);

  useEffect(() => {
    //only asign redirect when logged out and not when logging out
    //check if prevRedirectLocation is set to know if user was previously logged in
    if (
      location.pathname !== '/login' &&
      !inSession &&
      !prevRedirectLocation.current
    ) {
      window.sessionStorage.setItem(
        'redirectLocation',
        JSON.stringify(location)
      );
    }
  }, [location, inSession]);

  useEffect(() => {
    if (
      !!sessionLocation &&
      location.pathname === sessionLocation.pathname &&
      inSession
    ) {
      sessionStorage.removeItem('redirectLocation');
      prevRedirectLocation.current = location;
    } else {
      prevRedirectLocation.current = true;
    }
  }, [location.pathname, inSession]);

  if (loggedIn && isSync()) {
    return (
      <ErrorBoundary>
        <NavContext.Provider value={nav}>
          <div id={contentId}>
            <SyncNav />
            <AnimatedSwitch {...animatedSwitchProps}>
              <Route path="/sync" component={Sync} />
              <Route path="/sync_setting" component={SyncSetting} />
              <Redirect to="/sync" />
            </AnimatedSwitch>
          </div>
        </NavContext.Provider>
      </ErrorBoundary>
    );
  } else if (loggedIn && isClover && showClover) {
    return (
      <ErrorBoundary>
        <NavContext.Provider value={nav}>
          {showCloverMp ? <CloverNav /> : <SyncNav />}
          <div id={contentId}>
            <AnimatedSwitch {...animatedSwitchProps}>
              <Route path="/clover" component={Clover} />
              <Route path="/discount" component={DiscountSettings} />
              <Redirect to="/clover" />
            </AnimatedSwitch>
          </div>
        </NavContext.Provider>
      </ErrorBoundary>
    );
  } else if (loggedIn) {
    return (
      <ErrorBoundary>
        <NavContext.Provider value={nav}>
          {nav.showSidebar && <Sidebar />}
          <Navigation />
          <div className="flex">
            <div className="flex-1 h-14">
              <div
                className={cn({
                  'px-2': bp.lessThan.sm,
                  'px-3': bp.greaterThan.sm && bp.lessThan.md,
                  'px-28': bp.greaterThan.md
                })}
              >
                <AnimatedSwitch {...animatedSwitchProps}>
                  <Route path="/home" component={HomeWithFooter} />
                  <Route path="/chain" component={ChainWithFooter} />
                  <Route path="/profile" component={ProfileWithFooter} />
                  <Route path="/scandata" component={ScanDataWithFooter} />
                  <Route path="/auth/clover" component={CloverAuth} />
                  <Route
                    path="/engage/:tab?/:programId?"
                    component={EngageWithFooter}
                  />
                  <Route
                    path="/engage_checkout"
                    component={EngageCheckoutWithFooter}
                  />
                  <Route
                    path="/reimbursement/edit"
                    component={ReimbursementEditWithFooter}
                  />
                  <Route
                    path="/reimbursement"
                    component={ReimbursementWithFooter}
                  />
                  {redirect ? (
                    <Redirect to={sessionLocation} />
                  ) : (
                    <Redirect to="/home" />
                  )}
                </AnimatedSwitch>
              </div>
            </div>
          </div>
        </NavContext.Provider>
      </ErrorBoundary>
    );
  } else if (isClover && showClover) {
    return (
      <div id={contentId}>
        <AnimatedSwitch {...animatedSwitchProps}>
          <Route path="/clover_login" component={CloverLogin} />
          <Route path="/clover_signup" component={SignUp} />
          <Route path="/account_creation" component={AccountCreation} />
          <Route path="/verify_email" component={VerifyEmail} />
          <Route path="/reset_password" component={ResetPassword} />
          <Route path="/auto_login" component={AutoLogin} />
          <Route path="/auth/clover" component={CloverAuth} />
          <Redirect to="/clover_login" />
        </AnimatedSwitch>
      </div>
    );
  } else {
    return (
      <div id={contentId}>
        <AnimatedSwitch {...animatedSwitchProps}>
          <Route path="/login" component={Login} />
          <Route
            path="/signup"
            component={showNewEnrollFlow ? NewSignUp : SignUp}
          />
          <Route
            path="/account_creation"
            component={showNewEnrollFlow ? NewAccountCreation : AccountCreation}
          />
          <Route path="/verify_email" component={VerifyEmail} />
          <Route path="/reset_password" component={ResetPassword} />
          <Route path="/auto_login" component={AutoLogin} />
          <Route path="/auth/clover" component={CloverAuth} />
          <Redirect to="/login" />
        </AnimatedSwitch>
      </div>
    );
  }
}

export default AppRoutes;
