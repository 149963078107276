import { create } from 'apisauce';
import { pickBy, identity } from 'lodash';
import { webapi } from 'Config/Hosts';
import { getAppReducer } from 'app';

/*
  This withAuth is similar to what I did for relay Network layer
  It will assign accessToken to headers everytime before making request
  If accessToken expired, it will refresh the token and retry previous request

  Just need to keep an eye on we might potentially run into race condition

  po
*/

function withAuth(api) {
  const { axiosInstance } = api;
  axiosInstance.interceptors.request.use(config => {
    const [state] = getAppReducer();
    const { accessToken } = state.token || {};
    config.headers.Authorization = accessToken;
    return config;
  });

  axiosInstance.interceptors.response.use(
    res => res,
    error => {
      const [state, dispatch] = getAppReducer();
      const { refreshToken } = state.token || {};

      // 401 UNAUTHORIZED
      if (error.response.status === 401) {
        if (refreshToken) {
          return refresh(refreshToken).then(response => {
            if (response.ok) {
              const accessToken = response.data['access_token'];
              const refreshToken = response.data['refresh_token'];
              dispatch({
                type: 'TOKEN:SET',
                accessToken,
                refreshToken
              });
              return axiosInstance.request(error.config);
            } else {
              dispatch({
                type: 'TOKEN:RESET'
              });
              return Promise.reject(error);
            }
          });
        } else {
          // clear invalid accessToken
          dispatch({
            type: 'TOKEN:RESET'
          });
        }
      }

      return Promise.reject(error);
    }
  );
}

const option = {
  baseURL: `${webapi}/`,
  header: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json'
  },
  timeout: 30000
};

const api = create(option);
const authApi = create(option);

withAuth(authApi);

// Authorization
function login({ username, password }) {
  return api.post('/auth', {
    auth: {
      username,
      password
    }
  });
}
function logout(refreshToken) {
  return api.delete(`/auth/${refreshToken}`);
}
function refresh(refreshToken) {
  return authApi.post(`/auth/refresh/${refreshToken}`);
}
// use the api with auth token instead
function updatePassword(password) {
  const payload = {
    auth: {
      password
    }
  };
  return authApi.post(`/auth/update_password`, payload);
}
function resendVerification() {
  return authApi.post(`/resend_verification`);
}

// Reset Password
function requestResetPassword({ email }) {
  return api.post('/request_reset_password', { email });
}
function resetPassword({ token, password }) {
  return api.post('/reset_password', { token, password });
}

// Account Creation
const verifyCredentials = ({ email, username }) => {
  const params = pickBy(
    {
      email,
      username
    },
    identity
  );

  return api.get('/verify_credentials', params);
};

const signup = ({
  firstName,
  lastName,
  phoneNumber,
  email,
  chainName,
  chainState,
  storeAmount,
  refFrom,
  qualified
}) =>
  api.post('/signup', {
    firstName,
    lastName,
    phoneNumber,
    email,
    chainName,
    chainState,
    storeAmount,
    refFrom,
    qualified
  });

const checkSignup = () => authApi.get('/signup');

const createUser = ({ username, password }) =>
  authApi.post('/create_user', { username, password });

const createChain = ({ name, phoneNumber, street, city, state, zip }) =>
  authApi.post('/create_chain', {
    name,
    phoneNumber,
    address: {
      street,
      city,
      state,
      zip
    }
  });

const setupRecurly = ({ recurlyToken, coupon, street, city, state, zip }) =>
  authApi.post('/setup_recurly', {
    recurlyToken,
    coupon,
    billingAddress: {
      street,
      city,
      state,
      zip
    }
  });

const verifyCoupon = coupon => authApi.get(`/verify_coupon/?coupon=${coupon}`);

const fetchCloverMeta = ({ merchantId, authorizationCode }) =>
  api.get(`/fetch_clover_metadata/${merchantId}/${authorizationCode}`);

//New Enrollment Flow
const signup_v2 = ({
  firstName,
  lastName,
  phoneNumber,
  email,
  chainName,
  storeAmount,
  chainMan
}) =>
  api.post('/signup_v2', {
    firstName,
    lastName,
    phoneNumber,
    email,
    chainName,
    storeAmount,
    chainMan
  });

const auth_signup_v2 = ({
  firstName,
  lastName,
  phoneNumber,
  email,
  chainName,
  storeAmount,
  chainMan
}) =>
  authApi.post('/signup_v2', {
    firstName,
    lastName,
    phoneNumber,
    email,
    chainName,
    storeAmount,
    chainMan
  });

const createUser_v2 = ({ email, username, password }) =>
  authApi.post('/create_user_v2', { email, username, password });

const checkSignup_v2 = () => authApi.get('/signup_v2');

const setupRecurly_v2 = ({
  recurlyToken,
  coupon,
  name,
  phoneNumber,
  street,
  city,
  state,
  zip
}) =>
  authApi.post('/setup_recurly_v2', {
    recurlyToken,
    coupon,
    name,
    phoneNumber,
    billingAddress: {
      street,
      city,
      state,
      zip
    }
  });

const Webapi = {
  // Authorization
  login,
  logout,
  refresh,
  updatePassword,
  // Resend Verification Email
  resendVerification,
  // Reset Password
  requestResetPassword,
  resetPassword,
  // Account Creation
  verifyCredentials,
  signup,
  checkSignup,
  createUser,
  createChain,
  setupRecurly,
  verifyCoupon,
  fetchCloverMeta,
  // New Enrollment Flow
  signup_v2,
  auth_signup_v2,
  createUser_v2,
  checkSignup_v2,
  setupRecurly_v2
};

export default Webapi;
