import cn from 'classnames';

export default function Card({
  color = 'white',
  elevation = 'shadow2',
  selected = false,
  className,
  radius = 4,
  height,
  width,
  children,
  ...restProps
}) {
  const style = {};
  if (radius) style.borderRadius = radius;
  if (height) style.height = height;
  if (width) style.width = width;

  return (
    <div
      {...restProps}
      className={cn(
        'sku-card',
        color,
        { [elevation]: !!elevation, selected: selected },
        className
      )}
      style={style}
    >
      {children}
    </div>
  );
}
