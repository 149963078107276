import reduce from 'lodash/reduce';
import isArray from 'lodash/isArray';
import { DateTime } from 'luxon';

export function isSync() {
  return /SKUPOS-SYNC/.test(window.navigator.userAgent);
  //return true;
}

export function isIos() {
  return /iPhone|iPod|iPad/i.test(window.navigator.userAgent);
}

export function isAndroid() {
  return /Android/i.test(window.navigator.userAgent);
}

export function isMobile() {
  return isIos() || isAndroid();
}

export function getUrlfromEmail(email) {
  if (typeof email !== 'string') return null;
  const domain = email.split('@')[1];
  if (/gmail.com/i.test(domain)) {
    // gmail
    return 'https://mail.google.com';
  } else if (/yahoo.(com|co)/i.test(domain)) {
    // yahoo
    return 'https://mail.yahoo.com/';
  } else if (/(hotmail|live|msn|passport|outlook).(com|co)/i.test(domain)) {
    // ms
    return 'https://mail.live.com/';
  } else if (/(icloud|me).com/i.test(domain)) {
    // icloud
    return 'https://www.icloud.com/mail';
  } else if (/aol.com/i.test(domain)) {
    // aol
    return 'https://mail.aol.com';
  } else if (/(att|sbcglobal).net/i.test(domain)) {
    // att
    return 'https://www.att.net';
  } else if (/comcast.(com|net)/i.test(domain)) {
    // comcast
    return 'https://login.comcast.net/';
  } else {
    return null;
  }
}

// display: (123)456-7890
// input: 1234567890
export function formatPhoneNumber(s, type = 'display') {
  let m = s.match(/(\d{3})(\d{3})(\d{4})$/);
  if (m) {
    return type === 'display'
      ? `(${m[1]}) ${m[2]}-${m[3]}`
      : `${m[1]}${m[2]}${m[3]}`;
  } else {
    return s;
  }
}

// display: 123-456-7890
// input: 1234567890
export function formatNewPhoneNumber(s, type = 'display') {
  let m = s.match(/(\d{3})(\d{3})(\d{4})$/);
  if (m) {
    return type === 'display'
      ? `${m[1]}-${m[2]}-${m[3]}`
      : `${m[1]}${m[2]}${m[3]}`;
  } else {
    return s;
  }
}

export function parseQueryString(raw) {
  if (!raw) return {};
  return raw
    .replace('?', '')
    .split('&')
    .reduce((acc, p) => {
      let [key, value] = decodeURIComponent(p).split('=');
      // turn boolean to correct value
      if (/true|false/.test(value)) {
        value = value === 'true';
      }
      // deal with array
      if (/(\w+)\[\]$/.test(key)) {
        key = key.match(/(\w+)\[\]$/)[1];
        if (!isArray(acc[key])) acc[key] = [];
        acc[key].push(value);
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});
}

export function serializeQueryString(q) {
  if (!q) return '';
  return (
    '?' +
    reduce(
      q,
      (acc, value, key) => {
        if (
          (!!value || value === 0 || typeof value === 'boolean') &&
          value.toString()
        ) {
          if (isArray(value)) {
            value.forEach(v =>
              acc.push(
                `${encodeURIComponent(key + '[]')}=${encodeURIComponent(v)}`
              )
            );
          } else {
            acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
          }
        }
        return acc;
      },
      []
    ).join('&')
  );
}

export function outSyncDays(lastSync) {
  const lastSyncDate = DateTime.fromSeconds(lastSync).toUTC();

  return parseInt(
    DateTime.local()
      .diff(lastSyncDate)
      .as('days')
  );
}

export function isOutSync(lastSync) {
  return !lastSync || outSyncDays(lastSync) >= 1;
}

export function engageSubmitToGtm(info) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'engage_enrollStores',
      engageEnrollStores: {
        ...info
      }
    });
  }
}

export function getNodeID(type, dbId) {
  return window.btoa(`${type}-${dbId}`);
}

export function nonProdAttribute(attributeValue) {
  return process.env.REACT_APP_BUILD_ENV === 'prod'
    ? undefined
    : attributeValue;
}
