/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useBreakpoints from 'Hooks/useBreakpoints';
import { isMobile } from 'Services/Util';

export const NavContext = createContext();

// function getSidebarMode(bp) {
//   return bp.lessThan.md ? 'float' : 'fixed';
// }

// for Provider
export function useNav() {
  const bp = useBreakpoints();
  const location = useLocation();
  const history = useHistory();
  const [isOwner, setOwner] = useState(false); // isOwner is updated by Navigation
  const [backAction, setBackAction] = useState(null);
  const [showSidebar, setSidebar] = useState(false);
  // const [sidebarMode, setSidebarMode] = useState(getSidebarMode(bp));
  const [openPostVerifyMsg, setPostVerifyMsg] = useState(false);
  const diffBackTo = ['/sync_setting'];

  function toggleSidebar(force) {
    if (bp.lessThan.md || isMobile()) {
      setSidebar(s => {
        if (typeof force != 'undefined') {
          return !!force;
        } else {
          return !s;
        }
      });
    }
  }

  const navigate = useCallback(() => {
    const { goBack, to, replace } = backAction || {};

    if (goBack) {
      history.goBack();
    } else if (to) {
      history.push(to);
    } else if (replace) {
      history.replace(replace);
    }
  }, [backAction, history]);

  useEffect(() => {
    // setSidebarMode(getSidebarMode(bp));
    if (isOwner && diffBackTo.indexOf(location.pathname) === -1) {
      setBackAction(null);
    }
  }, [backAction, bp, location.pathname]);

  return {
    setOwner,
    toggleSidebar,
    showSidebar,
    backAction,
    setBackAction,
    navigate,
    openPostVerifyMsg,
    setPostVerifyMsg
  };
}

// for Consumer
export default function useNavContext() {
  return useContext(NavContext);
}
