/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo, Suspense } from 'react';
import { Decimal } from 'decimal.js';
import { isMobile } from 'Services/Util';

// Relay
import graphql from 'babel-plugin-relay/macro';
import { useQueryLoader, usePreloadedQuery } from 'react-relay';

import useNav from 'Hooks/useNav';

import Loader from 'sku-ui/Loader';
import Button from 'sku-ui/Button';
import { Toast } from 'disco-ui';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import EngageReimbursementCard from 'Components/EngageReimbursementCard';
import EngagePostVerificationModal from 'Components/EngagePostVerificationModal';

import './style.scss';

function EngageReimbursementDropdown({ queryRef, isOpen, onClick }) {
  const { openPostVerifyMsg, setPostVerifyMsg } = useNav();
  const [toast, setToast] = useState(null);

  const { viewer } = usePreloadedQuery(
    graphql`
      query EngageReimbursementDropdownQuery {
        viewer {
          storeChain {
            hasAcceptedPayoutTermsAndConditions
            connectedAccounts {
              dbId
              engageBalances {
                availableToWithdraw
                lastWithdrawAmount
                lastWithdrawDate
                mostRecentStatus
                pendingBalance
              }
              status
              stripeLinkedAccount {
                bankAccountLastFour
                bankName
                status
              }
            }
          }
        }
      }
    `,
    queryRef
  );

  const { storeChain } = viewer || {};
  const { connectedAccounts, hasAcceptedPayoutTermsAndConditions } =
    storeChain || {};

  const { engageBalances, status, stripeLinkedAccount } =
    connectedAccounts[0] || {};

  const { availableToWithdraw, pendingBalance } = engageBalances || {};

  const availableToWithdrawAmount = availableToWithdraw
    ? availableToWithdraw
    : '0.00';

  const pendingBalanceAmount = pendingBalance ? pendingBalance : '0.00';

  let totalAmount = new Decimal(availableToWithdrawAmount)
    .plus(pendingBalanceAmount)
    .toFixed(2);

  const displayEngageBalance = `TOTAL BALANCE $${totalAmount}`;

  function toggleOpen() {
    onClick();
    if (openPostVerifyMsg) {
      setPostVerifyMsg(false);
    }
  }

  // memorized to prevent over render
  const memoToast = useMemo(() => {
    return toast ? (
      <Toast
        content=""
        type="default"
        {...toast} // content and type
        onClose={() => setToast(null)}
        position="top-right"
      />
    ) : null;
  }, [toast]);

  return (
    <div className="EngageReimbursementDropdown">
      <div onClick={toggleOpen}>
        {isMobile() ? (
          <div className="EngageReimbursementDropdown--mobile flex items-center justify-center">
            <AttachMoneyIcon />
          </div>
        ) : (
          <Button className="mr-5" display="block" size="xs" pill>
            {displayEngageBalance}
          </Button>
        )}
      </div>
      {isOpen && (
        <EngageReimbursementCard
          engageBalances={engageBalances}
          connectedAcctStatus={status}
          stripeLinkedAccount={stripeLinkedAccount}
          setToast={setToast}
          availableToWithdrawAmount={availableToWithdrawAmount}
          pendingBalanceAmount={pendingBalanceAmount}
          totalAmount={totalAmount}
          hasAcceptedPayoutTermsAndConditions={
            hasAcceptedPayoutTermsAndConditions
          }
          closeCard={onClick}
        />
      )}
      {memoToast}
      {openPostVerifyMsg && (
        <EngagePostVerificationModal
          setPostVerifyMsg={setPostVerifyMsg}
          openPostVerifyMsg={openPostVerifyMsg}
        />
      )}
    </div>
  );
}

export default function EngageReimbursementDropdownLoader(props) {
  const [queryRef, loadQuery] = useQueryLoader(
    require('./__generated__/EngageReimbursementDropdownQuery.graphql')
  );

  useEffect(() => {
    loadQuery();
  }, [loadQuery]);

  return (
    <Suspense
      fallback={
        <div className="pr-5">
          <Loader size={20} color="primary" />
        </div>
      }
    >
      {queryRef != null ? (
        <EngageReimbursementDropdown queryRef={queryRef} {...props} />
      ) : null}
    </Suspense>
  );
}
