import { createReducer } from 'Reducers/helpers';

let cache = JSON.parse(localStorage.getItem('persist:chain')) || {
  stjSplash: { seen: false, lastShown: '' }
};

const initState = { ...cache };

// handerlers
function set(state, { name, data }) {
  return {
    ...state,
    [name]: data
  };
}

function reset(state) {
  return {
    ...initState
  };
}

const reducer = createReducer(
  {
    'CHAIN:SET': set,
    'CHAIN:RESET': reset
  },
  initState
);

export default reducer;
