import { Modal, Button } from 'disco-ui';

function EngagePostVerificationModal({ openPostVerifyMsg, setPostVerifyMsg }) {
  return (
    <div>
      <Modal
        className="EngageReimbursementDropdown--alertMsg p-4"
        isOpen={openPostVerifyMsg}
      >
        <h4 className="text-center">Transfer Available Funds Now</h4>

        <p className="text-center py-4">
          You have funds available to transfer! Click above to transfer your
          funds.
        </p>

        <div className="flex justify-center">
          <Button
            className="px-4"
            onClick={() => setPostVerifyMsg(false)}
            color="primary"
          >
            Got It
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default EngagePostVerificationModal;
