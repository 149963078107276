import { Modal, Card } from 'disco-ui';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

export default function SyncInfoModal({ syncInfo, isOpen, closeModal }) {
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <div className="flex justify-center items-center flex-col mx-4 my-2">
        <div className="flex items-center">
          <InfoOutlinedIcon
            sx={{ fontSize: 30, color: '#19bf82' }}
            className="mr-2"
          />
          <h3>App Version Info</h3>
        </div>
        {!!syncInfo && Object.keys(syncInfo).length ? (
          <Card className="my-3">
            <div className="SyncInfoModal--item p-2">
              <h5 className="SyncInfoModal--label text small uppercase mb-1">
                Sync
              </h5>
              <p>{syncInfo.sync}</p>
            </div>
            <div className="SyncInfoModal--item p-2">
              <h5 className="SyncInfoModal--label text small uppercase mb-1">
                Node
              </h5>
              <p>{syncInfo.node}</p>
            </div>
            <div className="SyncInfoModal--item p-2">
              <h5 className="SyncInfoModal--label text small uppercase mb-1">
                Electron
              </h5>
              <p>{syncInfo.electron}</p>
            </div>
            <div className="SyncInfoModal--item p-2">
              <h5 className="SyncInfoModal--label text small uppercase mb-1">
                Loyalty Configuration
              </h5>
              {syncInfo.loyaltyIsOverriding ? (
                <div className="flex items-center">
                  <CheckCircleIcon sx={{ color: '#19bf82' }} className="mr-2" />
                  <p>Config file is valid</p>
                </div>
              ) : (
                <div className="flex items-center">
                  <ErrorOutlineIcon
                    sx={{ color: '#f5c022' }}
                    className="mr-2"
                  />
                  <p>Invalid config file</p>
                </div>
              )}
              <div className="flex items-center">
                <FolderOpenIcon className="mr-2" />
                <p className="text small py-2">
                  {syncInfo.loyaltyConfigFilePath}
                </p>
              </div>
            </div>
          </Card>
        ) : (
          <div className="my-3">
            Sync app info is currently unavailable. Please try again later.
          </div>
        )}
      </div>
    </Modal>
  );
}
