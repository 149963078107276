import useToggle from 'Hooks/useToggle';

// Components
import { Button } from 'disco-ui';

// Icons
import MenuIcon from '@mui/icons-material/Menu';

function CloverLeftButton({ sidebar }) {
  const [isSidebarOpen, toggleSidebar] = useToggle(false);

  return (
    <div>
      {sidebar ? (
        <Button
          color="transparent"
          icon={<MenuIcon />}
          size="large"
          title="Menu"
          onClick={() => toggleSidebar()}
        />
      ) : null}

      {sidebar && isSidebarOpen && (
        <div onClick={() => toggleSidebar()}>{sidebar}</div>
      )}
    </div>
  );
}

export default CloverLeftButton;
