/**
 * @generated SignedSource<<7a73d305cbc33295ae55c21d51b536a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasAcceptedPayoutTermsAndConditions",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "StripeConnectedAccount",
  "kind": "LinkedField",
  "name": "connectedAccounts",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dbId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngageBalances",
      "kind": "LinkedField",
      "name": "engageBalances",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "availableToWithdraw",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastWithdrawAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastWithdrawDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mostRecentStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pendingBalance",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "StripeLinkedAccount",
      "kind": "LinkedField",
      "name": "stripeLinkedAccount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bankAccountLastFour",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bankName",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EngageReimbursementDropdownQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StoreChain",
            "kind": "LinkedField",
            "name": "storeChain",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EngageReimbursementDropdownQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StoreChain",
            "kind": "LinkedField",
            "name": "storeChain",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "720baadbe1c18571b66373c4d5456de7",
    "id": null,
    "metadata": {},
    "name": "EngageReimbursementDropdownQuery",
    "operationKind": "query",
    "text": "query EngageReimbursementDropdownQuery {\n  viewer {\n    storeChain {\n      hasAcceptedPayoutTermsAndConditions\n      connectedAccounts {\n        dbId\n        engageBalances {\n          availableToWithdraw\n          lastWithdrawAmount\n          lastWithdrawDate\n          mostRecentStatus\n          pendingBalance\n        }\n        status\n        stripeLinkedAccount {\n          bankAccountLastFour\n          bankName\n          status\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "7e1eee2e54edb224d456a5cf33f4a06c";

module.exports = node;
