import cn from 'classnames';

export default function SkuLoader({
  display = 'block',
  color,
  size,
  label,
  labelSize = 14,
  strokeWidth = 4
}) {
  return (
    <div className={cn('sku-loader', display, color)}>
      {/* By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL */}
      <div className="sku-loader--wrapper">
        <svg
          width="1em"
          height="1em"
          viewBox="-6 -6 46 46"
          xmlns="http://www.w3.org/2000/svg"
          className="sku-loader--indicator inline-block"
          style={{ fontSize: size }}
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(1 1)" strokeWidth={strokeWidth}>
              <circle strokeOpacity=".15" cx="18" cy="18" r="18" />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="0.8s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
        {label && (
          <div className="sku-loader--label" style={{ fontSize: labelSize }}>
            {label}
          </div>
        )}
      </div>
    </div>
  );
}
