import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

export default function EngageBalanceWithdraw() {
  const [
    engageBalanceWithdraw,
    engageBalanceWithdrawInFlight
  ] = useMutation(graphql`
    mutation EngageBalanceWithdrawMutation(
      $input: EngageBalanceWithdrawInput!
    ) {
      engageBalanceWithdraw(input: $input) {
        clientMutationId
        viewer {
          storeChain {
            connectedAccounts {
              dbId
              status
              stripeAccountId
              stripeLinkedAccount {
                bankAccountLastFour
                bankAccountRoutingNumber
                dbId
                status
                stripeConnectedAccountId
              }
            }
          }
        }
      }
    }
  `);

  return {
    engageBalanceWithdraw,
    engageBalanceWithdrawInFlight
  };
}
