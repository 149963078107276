/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment } from 'react';
import { isMobile } from 'Services/Util';
import useAppReducer from 'Hooks/useAppReducer';
import { useHistory, useLocation } from 'react-router-dom';

// react-router
import { Link } from 'react-router-dom';

// Components
import Dropdown from 'sku-ui/Dropdown';
import Button from 'sku-ui/Button';

// Icons
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SyncIcon from '@mui/icons-material/Sync';
import HelpIcon from '@mui/icons-material/Help';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BookIcon from '@mui/icons-material/Book';

import { ReactComponent as SkuposTourIcon } from 'Assets/SkuposTourIcon.svg';

// Constants
import SYNC_DOWNLOAD_URL from 'Constants/SYNC_DOWNLOAD_URL';

function NavDropdown({
  title,
  showTour,
  hasSyncStore,
  setOpenSyncModal,
  isOpen,
  onClick
}) {
  const [appState, dispatch] = useAppReducer();
  const { clover } = appState;
  const { pathname } = useLocation();
  const { push } = useHistory();

  const showDownload = !(isMobile() || clover.merchantId);

  const handleTourClick = e => {
    onClick();
    if (pathname !== '/home') push('/home');
    e.preventDefault();
    e.stopPropagation();
    const intercom = window.Intercom || function() {};
    intercom('startTour', 505402);
  };

  const icon = (
    <div className="Navigation--profile flex items-center justify-center">
      {title}
    </div>
  );

  return (
    <div>
      <Dropdown
        className="NavHeader--menu"
        position="bottom right"
        width={210}
        menuVerticalOffset={56}
        button={
          <Button
            className="flex items-center"
            size="sm"
            color="text-white ring-transparent"
            border="border-0"
          >
            <div className="NavHeader--avatar mr-2">{icon}</div>
            {!isMobile() && <ExpandMoreIcon />}
          </Button>
        }
        isOpenCustom={isOpen}
        onToggleCustom={onClick}
        customDropdown={true}
      >
        {showDownload && (
          <Fragment>
            <div className="sku-dropdown--label">SETUP</div>
            {hasSyncStore ? (
              <div onClick={() => setOpenSyncModal(true)}>
                <FileDownloadIcon
                  sx={{ fontSize: 20 }}
                  className="mr-2 inline-block text-indigo-500"
                />
                Download SYNC
              </div>
            ) : (
              <a
                href={`${SYNC_DOWNLOAD_URL}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FileDownloadIcon
                  sx={{ fontSize: 20 }}
                  className="mr-2 inline-block text-indigo-500"
                />
                Download SYNC
              </a>
            )}
          </Fragment>
        )}
        {!!clover.merchantId && (
          <Fragment>
            <div className="sku-dropdown--label">SETUP</div>
            <a
              onClick={() => dispatch({ type: 'CLOVER:SET', cloverMode: true })}
            >
              <SyncIcon
                sx={{ fontSize: 20 }}
                className="mr-2 inline-block text-indigo-500"
              />
              Switch to Sync
            </a>
          </Fragment>
        )}
        <div className="sku-dropdown--label">SUPPORT</div>
        <a
          className="helpDesk"
          href="https://help.skupos.com/en/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <HelpIcon
            sx={{ fontSize: 20 }}
            className="mr-2 inline-block text-indigo-500"
          />
          Help Desk
        </a>
        <a
          href="https://7551458.fs1.hubspotusercontent-na1.net/hubfs/7551458/Skupos_Training-Manual_2023-1.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BookIcon
            sx={{ fontSize: 20 }}
            className="mr-2 inline-block text-indigo-500"
          />
          Employee Handbook
        </a>
        {showDownload && showTour && (
          <a href="#" alt="Start Tour" onClick={handleTourClick}>
            <SkuposTourIcon
              width={20}
              height={20}
              className="mr-2 inline-block"
            />
            Tour
          </a>
        )}
        <div className="sku-dropdown--label">ACCOUNT</div>
        <Link to="/profile">
          <SettingsIcon
            sx={{ fontSize: 20 }}
            className="mr-2 inline-block text-indigo-500"
          />
          Settings
        </Link>
        <a onClick={() => dispatch({ type: 'TOKEN:RESET' })}>
          <ExitToAppIcon
            sx={{ fontSize: 20 }}
            className="mr-2 inline-block text-indigo-500"
          />
          Logout
        </a>
      </Dropdown>
    </div>
  );
}

export default NavDropdown;
