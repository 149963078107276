import cn from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';

import './style.scss';

function PromosSubNav() {
  const history = useHistory();
  const location = useLocation();

  const performanceActive = location.pathname.includes('/engage/performance');

  return (
    <div className="PromosSubNav flex z-10 bg-white h-11 pl-28 shadow-3">
      <div
        className={cn('PromosSubNavItem', !performanceActive && 'active')}
        onClick={() => {
          history.push(`/engage/manage`);
        }}
      >
        Manage
      </div>
      <div
        className={cn('PromosSubNavItem', performanceActive && 'active')}
        onClick={() => {
          history.push(`/engage/performance`);
        }}
      >
        Performance
      </div>
    </div>
  );
}

export default PromosSubNav;
