import { useMemo, createContext, useContext } from 'react';

import useMedia from 'Hooks/useMedia';

export const BreakpointsContext = createContext();

// for provider
export function useBreakpoints({
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200
} = {}) {
  const greaterThanSm = useMedia(`(min-width: ${sm}px)`);
  const greaterThanMd = useMedia(`(min-width: ${md}px)`);
  const greaterThanLg = useMedia(`(min-width: ${lg}px)`);
  const greaterThanXl = useMedia(`(min-width: ${xl}px)`);
  const lessThanSm = useMedia(`(max-width: ${sm}px)`);
  const lessThanMd = useMedia(`(max-width: ${md}px)`);
  const lessThanLg = useMedia(`(max-width: ${lg}px)`);
  const lessThanXl = useMedia(`(max-width: ${xl}px)`);

  return useMemo(
    () => ({
      greaterThan: {
        sm: greaterThanSm,
        md: greaterThanMd,
        lg: greaterThanLg,
        xl: greaterThanXl
      },
      lessThan: {
        sm: lessThanSm,
        md: lessThanMd,
        lg: lessThanLg,
        xl: lessThanXl
      }
    }),
    [
      greaterThanSm,
      greaterThanMd,
      greaterThanLg,
      greaterThanXl,
      lessThanSm,
      lessThanMd,
      lessThanLg,
      lessThanXl
    ]
  );
}

// for consumer
export default function useBreakpointsContext() {
  return useContext(BreakpointsContext);
}
