import cn from 'classnames';

export default function Toggle({
  color = 'green',
  className,
  type = 'checkbox',
  children,
  disabled = false,
  inverted = false,
  readOnly = false,
  onChange,
  ...restProps
}) {
  return (
    <div
      className={cn('sku-toggle flex items-center', color, className, {
        disabled,
        inverted
      })}
      onClick={onChange}
    >
      <input
        className={cn({ readOnly })}
        type={type}
        disabled={disabled}
        readOnly={readOnly}
        onChange={onChange}
        {...restProps}
      />
      {children && <div className="ml-2">{children}</div>}
    </div>
  );
}
