/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';

import useAppReducer from 'Hooks/useAppReducer';

// Components
import { Dropdown } from 'disco-ui';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import SyncInfoModal from 'Components/SyncInfoModal';

// Icons
import AccountBoxIcon from '@mui/icons-material/AccountBox';

function openExternal(url) {
  if (window.require) {
    const shell = window.require('electron').shell;
    shell.openExternal(url);
  } else {
    window.open(url, '_blank', 'noopener, noreferrer');
  }
}

function SyncDropdown({ title, logout, syncInfo }) {
  const [appState, dispatch] = useAppReducer();
  const [openModal, setOpenModal] = useState(false);

  const { clover } = appState;
  return (
    <div>
      <Dropdown
        display="inline-block"
        color="transparent"
        position="bottom right"
        size="large"
        icon={<AccountBoxIcon />}
        title={title}
      >
        <div>Account</div>
        {!!clover.merchantId && (
          <a
            onClick={() => dispatch({ type: 'CLOVER:SET', cloverMode: false })}
          >
            Switch to Dashboard
          </a>
        )}
        <a onClick={() => dispatch({ type: 'TOKEN:RESET' })}>Logout</a>
        <div>Support</div>
        <a onClick={() => openExternal('https://help.skupos.com/en/')}>
          Help Desk
        </a>
        <div className="flex items-center">
          Sync App Info <HelpOutlineOutlinedIcon sx={{ fontSize: 12 }} />
        </div>
        <a onClick={() => setOpenModal(true)}>App Version Info</a>
      </Dropdown>
      <SyncInfoModal
        syncInfo={syncInfo}
        isOpen={openModal}
        closeModal={() => setOpenModal(false)}
      />
    </div>
  );
}

export default SyncDropdown;
