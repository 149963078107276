import {
  Environment,
  Network,
  RecordSource,
  Store,
  QueryResponseCache
} from 'relay-runtime';
import { getAppReducer } from 'app';
import api from 'Services/WebApi';
import { webapi } from 'Config/Hosts';

// import { installRelayDevTools } from 'relay-devtools'
// installRelayDevTools()

export const cache = new QueryResponseCache({
  size: 250,
  ttl: 60 * 1000
});

function fetchQuery(operation, variables, cacheConfig, uploadables) {
  const queryID = operation.text;
  const isQuery = operation.operationKind === 'query';
  const isMutation = operation.operationKind === 'mutation';
  const forceFetch = cacheConfig && cacheConfig.force;

  // Try to get data from cache on queries
  const fromCache = cache.get(queryID, variables);
  if (isQuery && fromCache !== null && !forceFetch) {
    return fromCache;
  }

  // Otherwise, fetch data from server
  const [state] = getAppReducer();
  const { accessToken } = state.token || {};

  return fetch(`${webapi}/graphql`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: accessToken
    },
    body: JSON.stringify({
      query: operation.text, // GraphQL text from input
      variables
    })
  })
    .then(response => {
      const [state, dispatch] = getAppReducer();
      const { refreshToken } = state.token || {};
      // 401 UNAUTHORIZED
      if (response.status === 401) {
        if (refreshToken) {
          return api.refresh(refreshToken).then(response => {
            if (response.ok) {
              const accessToken = response.data['access_token'];
              const refreshToken = response.data['refresh_token'];
              dispatch({
                type: 'TOKEN:SET',
                accessToken,
                refreshToken
              });
              return fetchQuery(operation, variables, cacheConfig, uploadables);
            } else {
              dispatch({
                type: 'TOKEN:RESET'
              });
              return response.json();
            }
          });
        } else {
          // clear invalid accessToken
          dispatch({
            type: 'TOKEN:RESET'
          });
        }
      }

      return response.json();
    })
    .then(json => {
      // Update cache on queries
      if (isQuery && json && !json.errors) {
        cache.set(queryID, variables, json);
      }

      // Clear cache on mutations
      if (isMutation) {
        cache.clear();
      }

      if (isMutation && json.errors) {
        return Promise.reject(json.errors);
      }

      return json;
    });
}

// Create a network layer from the fetch function
const network = Network.create(fetchQuery);
const source = new RecordSource();
const store = new Store(source);
const env = new Environment({
  network,
  store
});

export default env;
