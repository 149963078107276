import { BrowserRouter } from 'react-router-dom';

// relay
import { RelayEnvironmentProvider } from 'react-relay';
import Environment from 'Relay/environment';

// Components
import ScrollToTop from 'Components/ScrollToTop';
import Routes from 'Routes';

// hooks
import { AppReducerContext, useAppReducer } from 'Hooks/useAppReducer';
import { BreakpointsContext, useBreakpoints } from 'Hooks/useBreakpoints';

// styles
import 'Styles/index.css';
import 'sku-ui/components.scss';
import 'overlayscrollbars/css/OverlayScrollbars.css';

// hackish way to expose appReducer for Relay network layer
let appReducer;
export function getAppReducer() {
  return appReducer;
}

function App() {
  appReducer = useAppReducer();
  const bp = useBreakpoints();

  return (
    <RelayEnvironmentProvider environment={Environment}>
      <AppReducerContext.Provider value={appReducer}>
        <BreakpointsContext.Provider value={bp}>
          <BrowserRouter>
            <ScrollToTop>
              <Routes />
            </ScrollToTop>
          </BrowserRouter>
        </BreakpointsContext.Provider>
      </AppReducerContext.Provider>
    </RelayEnvironmentProvider>
  );
}

export default App;
