/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment } from 'react';

// Components
import Dropdown from 'sku-ui/Dropdown';
import Button from 'sku-ui/Button';

// Icons
import AppsIcon from '@mui/icons-material/Apps';
import pdiLogo from 'Assets/logo_pdi_cstore_essentials.png';

export default function AppSwitcherDropdown({ isOpen, onClick }) {
  return (
    <div>
      <Dropdown
        position="bottom right"
        width={210}
        menuVerticalOffset={58}
        button={
          <Button
            className="flex items-center"
            size="sm"
            color="text-white ring-transparent"
            border="border-0"
          >
            <AppsIcon sx={{ fontSize: 35 }} />
          </Button>
        }
        isOpenCustom={isOpen}
        onToggleCustom={onClick}
        customDropdown={true}
      >
        <Fragment>
          <div className="sku-dropdown--label">APP SWITCHER</div>
          <a
            href={`${process.env.REACT_APP_PDI_CSE_URL}`}
            rel="noopener noreferrer"
          >
            <img
              className="pdiLogo"
              src={pdiLogo}
              alt="PDI Cstore Essentials"
            />
          </a>
        </Fragment>
      </Dropdown>
    </div>
  );
}
