module.exports = {
  multiPackDiscount: 'https://help.skupos.com/en/',
  terms: 'https://www.skupos.com/terms',
  privacy: 'https://www.skupos.com/privacy',
  requirements: 'https://help.skupos.com/en/',
  altria: 'https://www.insightsc3m.com/',
  rjr: 'https://myretailerdata.msa.com/id/#/login',
  helpDeskSync: 'https://help.skupos.com/en/',
  helpSyncDisconnected: 'https://help.skupos.com/en/'
};
