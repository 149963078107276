import cn from 'classnames';

// optional module react-router-dom
let Link;
try {
  Link = require('react-router-dom').Link;
} catch (err) {
  Link = null;
}

const COLOR_PRESET = {
  default:
    'bg-gray-600 border-gray-600 ring-transparent hover:bg-gray-500 text-white',
  white: 'bg-white border-green-500 ring-green-50 text-green-500',
  clear: 'bg-white border-gray-100 ring-gray-50 text-gray-300',
  primary:
    'bg-green-500 border-green-500 ring-green-200 hover:ring-green-200 text-white',
  warning:
    'bg-yellow-500 border-yellow-500 ring-yellow-50 hover:bg-yellow-400 text-white',
  danger: 'bg-red-500 border-red-500 ring-red-50 hover:bg-red-400 text-white',
  info: 'bg-blue-500 border-blue-500 ring-blue-50 hover:bg-blue-400 text-white',
  gray:
    'bg-gray-50 border-gray-100 ring-transparent hover:bg-opacity-50 text-current',
  aqua: 'bg-aqua-500 border-aqua-500 ring-aqua-50 hover:bg-aqua-400 text-white',
  indigo:
    'bg-indigo-500 border-indigo-600 ring-transparent hover:bg-indigo-400 text-white'
};

const SIZE_PRESET = {
  xs: 'text-xs px-2.5 py-2',
  sm: 'text-sm px-3 py-2',
  md: 'px-4 py-2.5',
  lg: 'px-6 py-2.5',
  xl: 'text-lg px-8 py-3.5'
};

const ROUNDED = {
  xs: 'rounded',
  sm: 'rounded',
  md: 'rounded',
  lg: 'rounded-md',
  xl: 'rounded-lg'
};

export default function Button({
  color = 'primary',
  size = 'md',
  display = 'inline-block',
  transition = 'transition-all ease-in-out',
  cursor = 'cursor-pointer',
  border = 'border',
  pill = false,
  disabled = false,
  className,
  children,
  ...restProps
}) {
  const btnClassName = cn(
    'sku-button outline-none focus:outline-none',
    display,
    COLOR_PRESET[color] || color,
    SIZE_PRESET[size] || size,
    border,
    pill ? 'rounded-full' : ROUNDED[size],
    disabled ? 'opacity-50 pointer-events-none' : 'hover:ring-4',
    cursor,
    transition,
    className
  );

  if (restProps?.to && !Link) {
    console.log(
      `If you want to use "to" props on Button. You need to install react-router-dom.`
    );
  }

  return Link && restProps?.to ? (
    <Link className={btnClassName} {...restProps}>
      {children}
    </Link>
  ) : restProps?.href ? (
    <a className={btnClassName} {...restProps}>
      {children}
    </a>
  ) : restProps?.type ? (
    <button className={btnClassName} disabled={disabled} {...restProps}>
      {children}
    </button>
  ) : (
    <div className={btnClassName} {...restProps}>
      {children}
    </div>
  );
}
