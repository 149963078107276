import { createReducer } from 'Reducers/helpers';

const initState = {
  clover: process.env.REACT_APP_ENABLE_CLOVER === '1' || false,
  engage: process.env.REACT_APP_ENABLE_ENGAGE === '1' || false,
  verifyCredentials:
    process.env.REACT_APP_ENABLE_VERIFY_CREDENTIALS === '1' || false,
  clover_mp: process.env.REACT_APP_ENABLE_CLOVER_MP === '1' || false,
  newEnrollFlow: process.env.REACT_APP_ENABLE_NEW_ENROLLFLOW === '1' || false,
  payout_connectedAccts: false
};

// handerlers
function set(state, { name, enable }) {
  return {
    ...state,
    [name]: !!enable
  };
}

function reset(state) {
  return {
    ...initState
  };
}

const reducer = createReducer(
  {
    'FEATURE:SET': set,
    'TOKEN:RESET': reset
  },
  initState
);

export default reducer;
