/* eslint-disable react-hooks/exhaustive-deps */
// abstract route to capture merchantId and authorizationCode from backend
import { useEffect } from 'react';
import { parseQueryString } from 'Services/Util';

import useAppReducer from 'Hooks/useAppReducer';

function CloverAuth(props) {
  const [, dispatch] = useAppReducer();

  useEffect(() => {
    const p = parseQueryString(props.location.search);

    const { merchantId, authorizationCode } = p;

    if (!!merchantId && !!authorizationCode) {
      dispatch({
        type: 'CLOVER:SET',
        cloverMode: true,
        merchantId,
        authorizationCode
      });
    }

    props.history.replace('/');
  }, []);

  return <div />;
}

export default CloverAuth;
