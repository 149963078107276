import { useState, useCallback } from 'react';

export default function useToggle(initState) {
  const [state, setter] = useState(initState);

  const toggle = useCallback(
    force => {
      let newState = !state;
      if (typeof force === 'boolean') {
        newState = force;
      }
      setter(newState);
    },
    [state]
  );

  return [state, toggle];
}
